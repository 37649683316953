export class Modal {
  static getElement() {
    if (document) {
      return document.querySelector("#modal");
    }
    return null;
  }
  static getProjectElement(project: string) {
    if (document) {
      return document.querySelector("#modal #" + project);
    }
    return null;
  }

  static getAllProjectElements = () => {
    if (document) {
      return Array.from(document.querySelectorAll("#modal .project"));
    }
    return [];
  };

  static isOpen() {
    const parent = Modal.getParent();
    return parent && parent.classList.contains("modal-active");
  }
  static close() {
    const parent = Modal.getParent();
    if (parent) {
      parent.classList.remove("modal-active");
    }
  }
  static getParent() {
    if (document) {
      return document.querySelector("body");
    }
  }
  static open(project: string) {
    const parent = Modal.getParent();
    if (parent) {
      parent.classList.add("modal-active");
    }
    Modal.getAllProjectElements().forEach((element: Element) => {
      element.style.display = "none";
    });
    const projectElement = Modal.getProjectElement(project);

    if (projectElement) {
      projectElement.style.display = "block";
      projectElement.focus();
    }
  }
}
